import React from "react";
import { Link } from "react-router-dom";
import "./CommonButtonStyle.scss";

const CommonButton = (props) => {
  return (
    <>
      {(() => {
        switch (props.role) {
          case "link":
            return (
              <Link
                to={props.to}
                className={`commonBtn ${
                  props.className ? props.className : ""
                }  ${props.icon ? "icon-btns" : ""}`}
              >
                {props.title && <>{props.title}</>}
                {props.icon && (
                  <>
                    <span className="btn-icon">{props.icon}</span>
                  </>
                )}
              </Link>
            );
          case "a-tag":
            return (
              <a
                target="_blank"
                rel="noreferrer"
                href={props.to}
                className={`commonBtn ${
                  props.className ? props.className : ""
                }  ${props.icon ? "icon-btns" : ""}`}
              >
                {props.title && <>{props.title}</>}
                {props.icon && (
                  <>
                    <span className="btn-icon">{props.icon}</span>
                  </>
                )}
              </a>
            );
          default:
            return (
              <button
                {...props}
                className={`commonBtn ${
                  props.className ? props.className : ""
                } ${props.icon ? "icon-btns" : ""}`}
                disabled={props.disabled}
              >
                {props.title && <>{props.title}</>}
                {props.icon && (
                  <>
                    <span className="btn-icon">{props.icon}</span>
                  </>
                )}
              </button>
            );
        }
      })()}
    </>
  );
};

export default CommonButton;
