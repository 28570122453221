import React from 'react'
import ExchangePlateform from '../LandingPage/ExchangePlateform/ExchangePlateform'
import FutureFinance from '../LandingPage/FutureFinance/FutureFinance'
import GraphSection from '../LandingPage/GraphSection/GraphSection'
import HeroSection from '../LandingPage/HeroSection/HeroSection'
import SignupSection from '../LandingPage/SignupSection/SignupSection'

const HomePages = () => {
  return (
    <>
      <HeroSection />
      <GraphSection />
      <ExchangePlateform />
      <FutureFinance />
      <SignupSection />
    </>
  )
}

export default HomePages
