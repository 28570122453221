import React, { useState, useRef, useEffect } from "react";

import { motion, useSpring } from "framer-motion";

import "./CommonCard.scss";

//Spring animation parameters
const spring = {
  type: "spring",
  stiffness: 300,
  damping: 30,
};

/**
 *
 * @framerSupportedLayoutWidth fixed
 * @framerSupportedLayoutHeight fixed
 */

function CommonCard(props) {
  const [rotateXaxis, setRotateXaxis] = useState(0);
  const [rotateYaxis, setRotateYaxis] = useState(0);
  const ref = useRef(null);

  const handleMouseMove = (event) => {
    const element = ref.current;
    const elementRect = element.getBoundingClientRect();
    const elementWidth = elementRect.width;
    const elementHeight = elementRect.height;
    const elementCenterX = elementWidth / 2;
    const elementCenterY = elementHeight / 2;
    const mouseX = event.clientY - elementRect.y - elementCenterY;
    const mouseY = event.clientX - elementRect.x - elementCenterX;
    const degreeX = (mouseX / elementWidth) * -30; //The number is the rotation factor
    const degreeY = (mouseY / elementHeight) * -30; //The number is the rotation factor
    setRotateXaxis(degreeX);
    setRotateYaxis(degreeY);
  };

  const handleMouseEnd = () => {
    setRotateXaxis(0);
    setRotateYaxis(0);
  };

  const dx = useSpring(0, spring);
  const dy = useSpring(0, spring);

  useEffect(() => {
    dx.set(-rotateXaxis);
    dy.set(rotateYaxis);
  }, [dx,dy,rotateXaxis, rotateYaxis]);
  return (
    <>
      <motion.div
        ref={ref}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseEnd}
        style={{
          width: "100%",
          height: "100%",
          rotateX: dx,
          rotateY: dy,
        }}
        className="Cardbg-clr"
      >
        <div className={`Common_Card ${props.className}`}>
          <div className="Cardimg-wrap">{props.icon}</div>
          <h5>{props.title}</h5>
          <p>{props.info}</p>
        </div>
      </motion.div>
    </>
  );
}

export default CommonCard;
