import { motion } from "framer-motion";
import { Container } from "react-bootstrap";
import futre_logo from '../../../../assets/images/future-logo.svg';
import "./FutureFinance.scss";

function FutureFinance() {
    return (
        <>
            <section className="FutureFinance">
                <Container>
                    <motion.div
                        transition={{ duration: 0.5 }}
                        initial={{ opacity: 0, y: 150 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                    >
                        <div className="Finance_wrap">
                            <motion.div
                                transition={{ duration: 0.7 }}
                                initial={{ opacity: 0, y: 150 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                            >
                                {/* <FutureLogo /> */}
                                <img src={futre_logo} alt="future-logo" />
                                <motion.h3
                                    initial={{ opacity: 0, y: -45 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{
                                        type: "spring",
                                        delay: 0.4,
                                    }}
                                >Let’s Build The Future Of Finance. Together</motion.h3>
                                <motion.h2
                                    initial={{ opacity: 0, y: -45 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{
                                        type: "spring",
                                        delay: 0.4,
                                    }}
                                >You name it. We have it.</motion.h2>
                            </motion.div>
                        </div>
                    </motion.div>
                </Container>
            </section>
        </>
    );
}

export default FutureFinance;
