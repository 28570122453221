import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../Common/Header/Header';
import Footer from '../Common/Footer/Footer';
import './Layout.scss'

const Layout = () => {
    return (
        <>
            <div className='Homelayout'>
                <Header />
                <div className='Homelayout_inner'>
                    <Outlet />
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Layout
