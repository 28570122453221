import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonCard from "../../../Common/CommonCard/CommonCard";
import "./ExchangePlateform.scss";
import { motion } from "framer-motion";
import tradecard_icon1 from "../../../../assets/images/tradeIcon.svg";
import tradecard_icon2 from "../../../../assets/images/spoticon.svg";
import tradecard_icon3 from "../../../../assets/images/orderBook.svg";

const ExchangePlateform = () => {
  return (
    <>
      <section className="ExchangeSection">
        <Container>
          <motion.h2
            initial={{
              opacity: 0,
              y: 50,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              type: "spring",
              bounce: "0.6",
              duration: 1.4,
              delay: 0.05,
            }}
            className="text-center"
          >
            One <span className="text-orange">Exchange</span> Platform. <br />
            Everything you need to grow.
          </motion.h2>
          <Row className="justify-content-sm-center">
            <Col lg={4} md={4} sm={6} xs={12}>
              <motion.div
                initial={{
                  opacity: 0,
                  y: 50,
                }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                }}
                transition={{
                  type: "spring",
                  bounce: "0.6",
                  duration: 1.4,
                  delay: 0.2,
                }}
                className="h-100"
              >
                <CommonCard
                  icon={
                    <img
                      src={tradecard_icon1}
                      alt="icon"
                      className="card_icon"
                    />
                  }
                  title={"Spot Basket"}
                  info={
                    "Easily diversify your portfolio by investing in our curated crypto baskets. Whether you're a seasoned trader or just starting, these baskets provide balanced exposure to multiple assets, allowing you to capitalize on market trends with a single click."
                  }
                />
              </motion.div>
            </Col>
            <Col lg={4} md={4} sm={6} xs={12}>
              <motion.div
                initial={{
                  opacity: 0,
                  y: 50,
                }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                }}
                transition={{
                  type: "spring",
                  bounce: "0.6",
                  duration: 1.4,
                  delay: 0.4,
                }}
                className="h-100"
              >
                <CommonCard
                  icon={<img src={tradecard_icon2} alt="icon" />}
                  title={"Orderbook"}
                  info={
                    "Access real-time market data with our comprehensive orderbook. Track the latest buy and sell orders, market depth, and liquidity, ensuring transparency and precision for your trades."
                  }
                  // className="Cardbg-gren"
                />
              </motion.div>
            </Col>
            <Col lg={4} md={4} sm={6} xs={12}>
              <motion.div
                initial={{
                  opacity: 0,
                  y: 50,
                }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                }}
                transition={{
                  type: "spring",
                  bounce: "0.6",
                  duration: 1.4,
                  delay: 0.8,
                }}
                className="h-100"
              >
                <CommonCard
                  icon={<img src={tradecard_icon3} alt="icon" />}
                  title={"Trade"}
                  info={
                    "Experience seamless trading with an intuitive interface. Execute your trades with speed and accuracy, explore advanced charting tools, and manage your positions effortlessly—all from one powerful platform."
                  }
                  // className="Cardbg-lightgren"
                />
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ExchangePlateform;
