import React, { useRef } from "react";
import { Container } from "react-bootstrap";
import { animate, AnimatePresence, motion, stagger, useInView } from "framer-motion";
import CommonButton from "../../../Common/CommonButton/CommonButton";
import "./HeroSection.scss";
import {
  BnbIcon,
  BtcIcon,
  EthIcon,
  ExternalLinkIcon,
  TestIcon,
  TestIcon2,
  TestIcon3,
  TestIcon4,
} from "../../../../assets/svg/SvgIcons";
import { useEffect } from "react";

const HeroSection = () => {
  const variants = {
    initial: {
      y: [-60, 0],
      filter: "blur(4px)",
      rotateX: -40,
    },
    animate: {
      filter: "blur(0rem)",
      rotateX: 0,
      y: 0,
    }
  };

  const ref = useRef();
  const isInView = useInView(ref);
  const title = "Uniting the Best of Both Worlds"
  useEffect(() => {
    animate(
      "#subtitle span",
      {
        // x: [-20, 0],
        opacity: [0, 1],
        y: [100, 0],
      },
      {
        type: "spring",
        delay: stagger(0.04),
        // duration: 0.8,
      }
    )
    if (isInView) {
      animate()
    }
  }, [isInView])


  return (
    <section className="hero-section">
      <Container className="text-center">
        <div className="hero-section__wrap">
          <motion.h1
            id='title'
            variants={variants}
            initial={"initial"}
            whileInView={"animate"}
            transition={{
              duration: 0.6,
              type: "tween", stagger: "0.6", ease: "easeInOut",
            }}
          >
            World’s First <br />{" "}
            <span className="text-orange">Crypto Basket </span>
            Ecosystem
          </motion.h1>
          <h4
            id="subtitle"
            ref={ref}
          >
            {
              title.split(" ").map((item, index) => {
                return (
                  <>
                    {
                      item.split("").map(((letter, li, ar) => {
                        return (
                          <span key={letter + li} style={{ marginRight: li === ar.length - 1 ? 8 : 0 }}>
                            {letter}
                          </span>
                        )
                      }))
                    }
                  </>
                )
              })
            }
          </h4>
          <CommonButton
            title="Coming Soon"
            className="mx-auto d-inline-flex try_btn"
            icon={<ExternalLinkIcon />}
          />
          {/* </motion.div> */}
          <div className="icons-wrap icons-wrap-one">
            <TestIcon3 />
          </div>
          <div className="icons-wrap icons-wrap-two">
            <TestIcon2 />
          </div>
          <div className="icons-wrap icons-wrap-three">
            <BnbIcon />
          </div>
          <div className="icons-wrap icons-wrap-four">
            <BtcIcon />
          </div>
          <div className="icons-wrap icons-wrap-five">
            <TestIcon />
          </div>
          <div className="icons-wrap icons-wrap-six">
            <TestIcon4 />
          </div>
          <div className="icons-wrap icons-wrap-seven">
            <EthIcon />
          </div>
        </div>
        <AnimatePresence>
          <motion.div
            initial={{
              opacity: 0,
              y: 50,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              type: "spring",
              bounce: "0.6",
              duration: 1.4,
              delay: 0.05,
            }}
          >

            <h6 className="text-capitalize">
              "Trade derivative contracts without limits: enjoy low fees, deep
              liquidity, and support for multiple blockchains."{" "}
            </h6>
            <h2 className="h1 text-capitalize">
              Trade With Trade Qatobit exchange
            </h2>
          </motion.div>
        </AnimatePresence>
      </Container>
    </section>
  );
};

export default HeroSection;