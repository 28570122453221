import React from "react";
import { motion } from "framer-motion";
import { Container } from "react-bootstrap";
import "./SignupSection.scss";
import CommonButton from '../../../Common/CommonButton/CommonButton'

const SignupSection = () => {


  return (
    <section className="signup-sec">
      <Container>
        <div className="signup-sec_wrap">
          <motion.div
            transition={{ duration: 0.4 }}
            initial={{ opacity: 0, y: 150 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <motion.h3
              initial={{
                opacity: 0,
                y: 50,
              }}
              whileInView={{
                opacity: 1,
                y: 0,
              }}
              transition={{
                type: "spring",
                bounce: "0.4",
                duration: 1.4,
                delay: 0.05,
              }}
              className="text-center">
              Signup to be in a list of{" "}
              <span className="text-orange">beta</span> users and get early
              access!
            </motion.h3>
          </motion.div>
          <motion.div
            transition={{ duration: 0.4 }}
            initial={{ opacity: 0, y: 150 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <div className="signup-sec_form">
              <form>
                <div className="signup-sec_form_wrap">
                  <label>Your Name</label>
                  <input
                    type="text"
                    placeholder="Enter your name"
                    required
                  // value={name}
                  />
                </div>
                <div className="signup-sec_form_wrap">
                  <label>Email ID</label>
                  <input
                    type="email"
                    placeholder="Enter Your Email ID"
                    required
                  // value={email}
                  />
                </div>
                <div className="text-center">
                  <CommonButton
                    title="Become a beta tester"
                    className="mx-auto text-capitalize"
                  />
                </div>
              </form>
            </div>
          </motion.div>
        </div>
      </Container>
    </section>
  );
};

export default SignupSection;
