import { motion } from 'framer-motion';
import { Fragment } from "react";
import {
    DiscordIcon,
    FacebookIcon,
    InstagramIcon,
    LinkedinIcon,
    TwitterIcon,
    YoutubeIcon,
} from "../../../assets/svg/SvgIcons";
import "./SocialLinks.scss";

const SOCILA_LINKS = [
    {
        icon: <TwitterIcon />,
        href: "/",
    },

    {
        icon: <LinkedinIcon />,
        href: "/",
    },
    {
        icon: <InstagramIcon />,
        href: "/",
    },
    {
        icon: <DiscordIcon />,
        href: "/",
    },
    {
        icon: <FacebookIcon />,
        href: "/",
    },
    {
        icon: <YoutubeIcon />,
        href: "/",
    },
];

const SocialLinks = () => {
    return (
        <motion.div
            transition={{ duration: 0.6 }}
            initial={{ opacity: 0, y: 150 }}
            whileInView={{ opacity: 1, y: 0 }}
        >
            <ul className="social-links">
                {SOCILA_LINKS.map((item, key) => {
                    return (
                        <Fragment key={key}>
                            <li>
                                <a href={item.href} rel="noreferrer" target="_blank">
                                    <span className="social-links-icon">{item.icon}</span>
                                </a>
                            </li>
                        </Fragment>
                    );
                })}
            </ul>
        </motion.div>
    );
};

export default SocialLinks;
