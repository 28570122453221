import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import Layout from './components/Layout/Layout';
import HomePages from './components/Pages/HomePages/HomePages';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

function App() {
  const router = createBrowserRouter(
    [
      {
        path: "/",
        element: <Layout />,
        children:
          [
            {
              path: "/",
              element: (
                <HomePages />
              ),
            },
          ]
      }
    ]
  )
  return (
    <>
      <div>
        <RouterProvider router={router} />
      </div>
    </>
  );
}

export default App;
